import React, { useEffect } from 'react';
import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react'

const TermsAndConditions = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <Box width={"80%"} margin={"auto"}>
            <>
                {/* Hello world */}
                <p>
                    <b />
                </p>
                <h1
                    style={{
                        textAlign: "start",
                        color: "rgb(5, 43, 96)",
                        //   //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: "3.4375em",
                        border: 0
                    }}
                >
                    <b>Terms &amp; Conditions</b>
                </h1>
                <p />
                <p
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //   //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: "1.125em",
                        border: 0
                    }}
                >
                    THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF THE INFORMATION TECHNOLOGY
                    ACT, 2000 AND RULES MADE THEREUNDER. THIS ELECTRONIC RECORD IS GENERATED BY
                    A COMPUTER SYSTEM AND DOES NOT REQUIRE ANY PHYSICAL OR DIGITAL SIGNATURES.
                </p>
                <p
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: "1.125em",
                        border: 0
                    }}
                >
                    THESE TERMS AND CONDITIONS WERE UPDATED ON 10 January 2022 (hereinafter
                    referred as the <strong>“Agreement”/ “Terms of Use”)</strong> PLEASE
                    CAREFULLY READ THESE TERMS OF USE. BY PROVIDING YOUR CONSENT AND/OR USING
                    THIS PLATFORM YOU INDICATE YOUR UNDERSTANDING AND ACCEPTANCE OF THESE TERMS
                    OF USE.
                </p>
                <p>
                    <strong />
                </p>
                <h4
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: "1.25em",
                        border: 0
                    }}
                >
                    <strong>
                        <span style={{ fontSize: "inherit", border: 0 }}>1.</span>
                        <span style={{ border: 0 }}>INTRODUCTION</span>
                    </strong>
                </h4>
                <p />
                <ul
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: 16,
                        border: 0
                    }}
                >
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <span style={{ fontSize: "inherit", border: 0 }}>1.1.</span>
                        </strong>
                        <span style={{ border: 0 }}>
                            HPNpay Technologies Private Limited,  Having its registered office at Office no. 103 A,
                            RANI BAZAR Bikaner, Rajasthan 334001, INDIA, (hereinafter referred to as the <strong>“Company”</strong>, which
                            expression shall unless repugnant to the context or meaning thereof mean
                            and include its successors and permitted assigns) is engaged in the
                            Business (as defined below).
                        </span>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <span style={{ fontSize: "inherit", border: 0 }}>1.2.</span>
                        </strong>
                        <span style={{ border: 0 }}>
                            Company is inter alia engaged in the business of providing digital
                            /technological financial solutions to its customers in India through the
                            Platform (as defined below) either directly or through its business
                            partners including but not limited to Retail Partners (as defined
                            below). The services include access to digital financial services
                            provided by various service providers and non-financial products and
                            services including IRCTC Services (“<strong>Company Services</strong>”).
                        </span>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <span style={{ fontSize: "inherit", border: 0 }}>1.3.</span>
                        </strong>
                        <span style={{ border: 0 }}>
                            Company collaborates with various distributors of goods and services by
                            associating with them as their strategic business partners (“
                            <strong>BP(s)</strong>”) to assist the Company in appointing various
                            retail partners in various cities, managing such retail partners and in
                            certain cases also dissemination of Company Services to such retail
                            partners.
                        </span>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <span style={{ fontSize: "inherit", border: 0 }}>1.4.</span>
                        </strong>
                        <span style={{ border: 0 }}>
                            Company is now desirous of engaging with various retail partners to
                            assist the Consumers in providing Financial Services through their
                            retail outlets by making available the disseminated Company Services
                            and/or providing such other services as may be intimated by Company from
                            time to time (“<strong>Retail Partner(s)</strong>”).
                        </span>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <span style={{ fontSize: "inherit", border: 0 }}>1.5.</span>
                        </strong>
                        <span style={{ border: 0 }}>
                            You have expressed your desire of engaging with Company as Retail
                            Partner and in this regard have submitted information in the RAF to
                            Company and/or BPs and have also verified the contents in the RAF on
                            this Platform (“<strong>Verification Process</strong>”).
                        </span>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <span style={{ fontSize: "inherit", border: 0 }}>1.6.</span>
                        </strong>
                        <span style={{ border: 0 }}>
                            You understand, agree and acknowledge that your engagement as the Retail
                            Partner and the use of this Platform, Website and/or Company Services
                            including all information, tools and services available from this
                            Platform to the Retail Partner and the Consumer is conditioned upon the
                            successful completion of Verification Process and Your acceptance of all
                            terms, conditions, policies and notices stated here, the terms whereof
                            are subject to change at any time without prior notice to You. Any new
                            features or tools which are added to the current Platform shall also be
                            subject to this Agreement. To ensure that You are aware of the changes,
                            please review this Agreement and all the documents referred to hereunder
                            periodically.
                        </span>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <span style={{ fontSize: "inherit", border: 0 }}>1.7.</span>
                        </strong>
                        <span style={{ border: 0 }}>
                            In the event You are representing an incorporated entity, You hereby
                            confirm that You have been expressly authorized by such entity to
                            provide consent to this Agreement, and such entity agrees to be bound by
                            the terms hereunder.
                        </span>
                    </li>
                </ul>
                <p
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: "1.125em",
                        border: 0
                    }}
                >
                    In this Agreement, Retail Partner and Company are individually referred to
                    as “<strong>Party</strong>” and collectively referred to as “
                    <strong>Parties</strong>”
                </p>
                <p>
                    <strong />
                </p>
                <h4
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: "1.25em",
                        border: 0
                    }}
                >
                    <strong>
                        <span style={{ fontSize: "inherit", border: 0 }}>2.</span>DEFINITIONS AND
                        INTERPRETATION
                    </strong>
                </h4>
                <p />
                <ul
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: 16,
                        border: 0
                    }}
                >
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <strong>
                                <span style={{ fontSize: "inherit", border: 0 }}>2.1</span>
                            </strong>
                        </strong>{" "}
                        <span style={{ border: 0 }}>
                            In the Agreement, unless the contrary intention appears and/or the
                            context otherwise requires, capitalized terms defined by: (i) inclusion
                            in quotations and/ or parenthesis have the meanings so ascribed; (ii)
                            the capitalized terms used herein and not defined in this Agreement
                            shall have the meaning ascribed to them in Company Rules and/or Website;
                            and (iii) the following terms shall have the meanings assigned to them
                            herein below:
                        </span>
                        <br />
                        <strong>“Agreement” / “Terms of Use”</strong> means these terms and
                        conditions and all their modifications from time to time in accordance
                        with the provisions contained herein, together with all schedules,
                        annexures and exhibits hereto;
                        <br />
                        <strong>“Applicable Law”</strong> includes all applicable Indian statutes,
                        enactments, acts of the state legislature or parliament, laws, ordinances,
                        rules, bye-laws, regulations, notifications, guidelines, directions,
                        directives and orders of any governmental authority, statutory authority,
                        board as may be applicable, including but not limited to any guidelines
                        and/or directions issued by the Reserve Bank of India and in each case,
                        any implementing regulation or interpretation issued thereunder including
                        any successor Applicable Law;
                        <br />
                        <strong>“Business”</strong> shall mean the business of providing various
                        financial and non-financial services to its customers, either directly or
                        through its business partners, by the means of a technological solution
                        and/or by setting up a network of stores and agents to facilitate the
                        digital dissemination of such financial / non-financial services;
                        <br />
                        <strong>“Business Day”</strong> means any day of the week (excluding
                        Saturdays, Sundays and public holidays) or a day on which banking
                        institutions in India are open for general business;
                        <br />
                        <br />
                        <strong>“Confidential Information”</strong> means all information (whether
                        oral or recorded in any medium) relating to the business, financial or
                        other affairs (including future plans) of the Company, Retail Partners ,
                        Consumers which is treated by the Company, as confidential, or is marked
                        or is by its nature confidential, including but not limited to all
                        Intellectual Property belonging to the Company, their affiliates or any of
                        the other Retail Partners or BPs as the case may be, together with the
                        existence and contents of this Agreement (including all Schedules), any
                        ancillary documents and the negotiations relating to this Agreement;
                        <br />
                        <p style={{ border: 0 }}>
                            <strong>“Competing Business”</strong> shall mean:
                        </p>
                        <ul style={{ border: 0 }}>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(i)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    Business; and/or
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(ii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    consulting in the area of Business.
                                </span>
                            </li>
                            “Consumer”
                        </ul>
                        <strong /> shall mean the consumer that undertakes a transaction on the
                        Platform and /or avail Company Services and that is bound by the
                        consumer&nbsp;<u style={{ border: 0 }}>terms and conditions</u> between
                        the Company and such consumer;
                        <br />
                        <strong>“Effective Date”</strong> shall mean the date of Retail Partner’s
                        acceptance of this Agreement;
                        <br />
                        <strong>“Financial Services”</strong> shall mean the financial services
                        provided by various service providers to the Consumers utilizing the
                        Company Services on the Platform;
                        <br />
                        <strong>“Governmental Authority”</strong> means any nation, state,
                        sovereign, or government, any federal, regional, state, local or political
                        subdivision and any entity exercising executive, legislative, judicial,
                        regulatory or administrative functions of or pertaining to government,
                        constitutionally established and having jurisdiction over any of the
                        Parties (to the extent relevant to the transactions contemplated hereby)
                        or the assets or operations of any of the foregoing or the transactions
                        contemplated hereby;
                        <br />
                        <strong>“INR”</strong> or&nbsp;<strong>“RS”</strong> means Indian Rupees,
                        the lawful currency of the Republic of India;
                        <br />
                        <strong>“IRCTC”</strong> shall mean the Indian Railway Catering and
                        Tourism Corporation;
                        <br />
                        <strong>“IRCTC Services”</strong> shall mean the services provided by the
                        Company acting as the PSP through its RSP(s) including but not limited to
                        booking and/or cancellation of train tickets;
                        <br />
                        <strong>“IRCTC T&amp;C”</strong> shall mean the terms and conditions
                        applicable to Retailer Partners acting as RSP for the Company for the
                        purpose of providing IRCTC Services to Consumers;
                        <br />
                        <strong>“IRCTC ID”</strong> shall mean the credentials assigned to RSP by
                        the PSP for the purpose of accessing the IRCTC Services;
                        <br />
                        <strong>“Intellectual Property”</strong> shall mean all intellectual
                        property used for the purpose of or in association with or in relation to
                        providing Company Services utilizing the Platform and includes without
                        limitation (a) Software, operating manuals, software code, program,
                        instructions, specifications, processes, input methods, data or
                        information used in relation to, in association with or for the operation
                        of the software installed by Company ; (b) the trademarks, service marks,
                        trade names, business names, logos, symbols, styles, colour combinations
                        used by Company during the course of its business and all depictions,
                        derivations and representations thereof; (c) all promotional material
                        including without limitation, advertisements, literature, graphics,
                        images, content and the ‘look and feel’ of all of the above; (d) all
                        information, data or material in whatever form, whether tangible or not,
                        provided by Company to Retail Partner during the course of or in relation
                        to the Services; and (e) all techniques, formulae, patterns, compilations,
                        processes, inventions, practices, methodology, techniques, improvement,
                        utility model, procedures, designs, skills, technical information, notes,
                        experimental results, service techniques, samples, specifications of the
                        products or services, labelling specifications, rights on software, and
                        any other knowledge or know-how of any nature whatsoever;
                        <br />
                        <strong>“Material Breach”</strong> shall mean any material breach of the
                        terms of this Agreement by Retail Partner including but not limited to any
                        breach of provisions of Clause 8 and Clause 9 of this Agreement;
                        <br />
                        <strong>“Person”</strong> shall mean any individual (including personal
                        representatives, executors or heirs of a deceased individual) or legal
                        entity, including but not limited to, any partnership, joint venture,
                        corporation, trust, unincorporated organization, limited liability
                        company, limited liability partnership or Governmental Authority;
                        <br />
                        <strong>“Platform”</strong> shall mean the Software accessible through the
                        Website;
                        <br />
                        <strong>“Proprietary Items”</strong> shall mean any document, record,
                        notebook, plan, model, component, device, communication device, computer
                        software or code, or Confidential Information or Trade Secret whether
                        embodied in a disk or in any other form, including electronic form owned
                        and/or used by the Company;
                        <br />
                        <strong>“Principal Service Provider” / “PSP”&nbsp;</strong>shall mean the
                        Company;
                        <br />
                        <strong>“RBI”</strong> shall mean the Reserve Bank of India;
                        <br />
                        <strong>“Retail Partner Application Form”/ “RAF”</strong> shall mean the
                        retail partner application form provided to You by BPs and/or Company
                        along with Your details for the purpose of applying to the Company to be
                        engaged as its Retail Partner;
                        <br />
                        <strong>“Retail Outlet”</strong> shall mean the place of business as
                        provided in the RAF from where the Retail Partner, upon authorization from
                        Company, shall provide Services to Company and/or utilize / make available
                        the Company Services to be utilized by the Consumers;
                        <br />
                        <strong>“Retail Service Provider” / “RSP”</strong> shall mean the Retailer
                        Partner(s) who have been appointed by the Company for the purpose of
                        extending IRCTC Services to the Consumers;
                        <br />
                        <br />
                        <strong>“Services”</strong> shall mean: (a) services provided by Retail
                        Partners with respect to assisting the Consumers in making available the
                        Financial Services utilizing the Company Services and/or Platform at its
                        Retail Outlets or such other place as may be permitted by Company; and/or
                        (b) such other services as intimated by Company to Retail Partner from
                        time to time;
                        <br />
                        <strong>“Software”</strong> shall include custom built software that is
                        owned by Company, or software that has been licensed from third party
                        suppliers by Company and in relation to which Company has obtained the
                        right to sub license from such third party suppliers, as modified/
                        replaced from time to time, that enables Retail Partner to utilize Company
                        Services on communication devices such as computers, mobile phones and
                        other handheld wireless devices etc. as identified by Company from time to
                        time;
                        <br />
                        <strong>"Related Entities"</strong> shall mean any parent company,
                        subsidiaries, affiliated corporations, partnerships, or joint ventures of
                        the Company;
                        <br />
                        <strong>“Trade Secret”</strong> shall mean any information, user flow
                        steps, screens, including a formula, pattern, compilation, program,
                        device, method technique, or process that derives independent economic
                        value, actual or potential, from being not generally known to, and not
                        being readily ascertainable by proper means by, other persons who can
                        derive economic value from its disclosure or use, including but not
                        limited to the patented information and processes as well as the
                        unpatented information and processes comprising, underlying, arising from,
                        and associated with and/or used by the Company or any Related Entity;
                        <br />
                        <strong>“Tax”</strong> or&nbsp;<strong>“Taxes”</strong> shall mean any and
                        all taxes, cess, levies, imposts, duties, charges, deposits, fees,
                        deductions or withholdings that are, or that are to be, imposed, levied,
                        collected, withheld or assessed, together with any and all interest,
                        penalties, claims or other liabilities arising under or relating thereto;
                        <br />
                        <strong>“You”</strong> or&nbsp;<strong>“Your”</strong>; shall mean any
                        natural or legal person who has access to and is using the Platform for
                        the purpose of being engaged as a Retail Partner in accordance with the
                        terms of this Agreement; and
                        <br />
                        <strong>“Website”</strong> shall mean and include&nbsp;
                        <u>
                            <a
                                href="www.hpnpay.com"
                                style={{ color: "rgb(0, 0, 255)", border: 0 }}
                            >
                                www.hpnpay.com
                            </a>
                        </u>
                        , mobile application of Company, any successor website/ applications, any
                        website of Related Entity or any other channel facilitated and permitted
                        by Company including but not limited to App, any other digital medium
                        including phone, displays, emails, social media interfaces, messaging
                        interfaces, wallet, payment intermediaries using Company’s interface.
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <strong>
                                <span style={{ fontSize: "inherit", border: 0 }}>2.2</span>
                            </strong>
                        </strong>{" "}
                        <u style={{ border: 0 }}>Interpretation</u>: In this Agreement, unless the
                        context otherwise requires:
                        <ul style={{ border: 0 }}>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(i)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    the headings are inserted for ease of reference only and shall not
                                    affect the construction or interpretation of this Agreement;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(ii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    references to one gender include all genders;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(iii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    any reference to any enactment of statutory provision is a reference
                                    to it as it may have been, or may from time to time be, amended,
                                    modified, consolidated or re-enacted (with or without modification)
                                    and includes all instruments or orders made under such enactment;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(iv)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    words in the singular shall include the plural and vice versa;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(v)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    any reference of “days” would mean “calendar days” and similarly
                                    reference of year and month would mean “calendar month” and
                                    “calendar year”;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(vi)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    any reference to &nbsp;<strong>“intimation”</strong>and
                                    <strong>&nbsp;“intimated”</strong>&nbsp; shall include any
                                    intimations provided by Company on Website and/or Platform.
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(vii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    unless otherwise specified, time periods within or following which
                                    any payment is to be made or act is to be done shall be calculated
                                    by excluding the day on which the period commences and including the
                                    day on which the period ends and by extending the period to the next
                                    Business Day if the last day of such period is not a Business Day;
                                    and whenever any payment is to be made or action to be taken under
                                    this Agreement is required to be made or taken on a day other than a
                                    Business Day, such payment shall be made or action taken on the next
                                    Business Day; and
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(viii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    Any reference to “writing” shall include printing, typing,
                                    lithography, transmissions by facsimile or in electronic form
                                    (including e-mail) and other means of reproducing words in visible
                                    form including but not limited to any instructions provided by
                                    Company on Website and/or Platform.
                                </span>
                            </li>
                        </ul>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <strong>
                                <span style={{ fontSize: "inherit", border: 0 }}>2.3</span>
                            </strong>
                        </strong>
                        No provisions shall be interpreted in favour of, or against, any Party by
                        reason of the extent to which such Party or its counsel participated in
                        the drafting hereof or by reason of the extent to which any such provision
                        is inconsistent with any prior draft hereof.
                    </li>
                </ul>
                <p>
                    <strong />
                </p>
                <h4
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: "1.25em",
                        border: 0
                    }}
                >
                    <strong>
                        <span style={{ fontSize: "inherit", border: 0 }}>3.</span>
                        <span style={{ border: 0 }}>SCOPE OF THIS AGREEMENT</span>
                    </strong>
                </h4>
                <strong>
                    <h4
                        style={{
                            textAlign: "start",
                            color: "rgb(30, 30, 30)",
                            //backgroundColor: "rgb(247, 247, 247)",
                            fontSize: "1.25em",
                            border: 0
                        }}
                    >
                        <span style={{ fontSize: "inherit", border: 0 }}>3.1.</span>
                        <span style={{ border: 0 }}>ENAGEMENT AS THE RETAIL PARTNER</span>
                    </h4>
                </strong>
                <p />
                <ul
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: 16,
                        border: 0
                    }}
                >
                    <ul style={{ fontSize: "1.125em", border: 0 }}>
                        <li style={{ fontSize: "1.125em", border: 0 }}>
                            <span style={{ border: 0 }}>(i)</span>
                            <span style={{ fontSize: "0.875em", border: 0 }}>
                                During the Term of this Agreement and subject to the provisions of
                                this Agreement, Company herby engages You and You hereby agree to be
                                engaged as a Retail Partner. As Retail Partner, Company will allow you
                                to access the Website and/or Platform and depending upon the nature of
                                transactions, You shall: (a) provide Services to Company; and/or (b)
                                avail services from Company and/or third party service providers
                                through the Website and/or Platform.
                            </span>
                        </li>
                        <li style={{ fontSize: "1.125em", border: 0 }}>
                            <span style={{ border: 0 }}>(ii)</span>
                            <span style={{ fontSize: "0.875em", border: 0 }}>
                                During the Term and subject to the provisions of this Agreement, and
                                unless otherwise specified by Company. the Retail Partner shall at its
                                Retail Outlet:
                            </span>
                            <ul style={{ border: 0 }}>
                                <li style={{ fontSize: "1.125em", border: 0 }}>
                                    <span style={{ fontSize: "0.9375em", border: 0 }}>(a)</span>
                                    <span style={{ fontSize: "0.875em", border: 0 }}>
                                        assist Consumers in undertaking transactions and/or utilizing
                                        Company Services;
                                    </span>
                                </li>
                                <li style={{ fontSize: "1.125em", border: 0 }}>
                                    <span style={{ fontSize: "0.9375em", border: 0 }}>(b)</span>
                                    <span style={{ fontSize: "0.875em", border: 0 }}>
                                        provide such services to Company as is intimated in writing by
                                        Company;
                                    </span>
                                </li>
                                <li style={{ fontSize: "1.125em", border: 0 }}>
                                    <span style={{ fontSize: "0.9375em", border: 0 }}>(c)</span>
                                    <span style={{ fontSize: "0.875em", border: 0 }}>
                                        assist Company and/or BPs in the marketing of the Company
                                        Services; and/or
                                    </span>
                                </li>
                                <li style={{ fontSize: "1.125em", border: 0 }}>
                                    <span style={{ fontSize: "0.9375em", border: 0 }}>(d)</span>
                                    <span style={{ fontSize: "0.875em", border: 0 }}>
                                        undertake such other business activities as instructed by the
                                        Company and/or informed by BPs from time to time.
                                    </span>
                                </li>
                            </ul>
                        </li>
                        <li style={{ fontSize: "1.125em", border: 0 }}>
                            <span style={{ border: 0 }}>(iii)</span>
                            <span style={{ fontSize: "0.875em", border: 0 }}>
                                Retail Partner acknowledges and agrees that its appointment is subject
                                to its continuance compliance with the provisions of this Agreement.
                            </span>
                        </li>
                        <li style={{ fontSize: "1.125em", border: 0 }}>
                            <span style={{ border: 0 }}>(iv)</span>
                            <span style={{ fontSize: "0.875em", border: 0 }}>
                                Company may, in order to manage credit risk associated with the
                                transactions on Website / Platform, require Retail Partners to
                                maintain an amount in advance with Company either directly or with the
                                assistance of BP from time to time. Depending upon the nature of
                                Services, Company may restrict certain/all transactions on Website /
                                Platform on account of insufficiency of such advance amount as is
                                required for such Services. Company may rely on factors including but
                                not limited to the nature of Services and the performance record of
                                Retail Partner in evaluating the sufficiency of advance amount
                                required for any transaction on Website / Platform. The determination
                                of such sufficiency / insufficiency shall be at Company’s sole
                                discretion.
                            </span>
                        </li>
                    </ul>
                </ul>
                <p>
                    <strong />
                </p>
                <h4
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: "1.25em",
                        border: 0
                    }}
                >
                    <strong>
                        <span style={{ fontSize: "inherit", border: 0 }}>3.2.</span>
                        <span style={{ border: 0 }}>
                            ENGAGEMENT AS RETAIL SERVICE PROVIDER FOR IRCTC SERVICES
                        </span>
                    </strong>
                </h4>
                <p />
                <ul
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: "1.125em",
                        border: 0
                    }}
                >
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <span style={{ border: 0 }}>(i)</span>
                        <span style={{ fontSize: "0.875em", border: 0 }}>
                            During the Term of this Agreement and subject to the provisions of this
                            Agreement, PSP herby engages You and You hereby agree to be engaged as
                            RSP. As RSP, PSP will allow you to access the Website and/or Platform
                            and will assign You with the IRCTC ID which shall enable You to provide
                            IRCTC Services to Consumers.
                        </span>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <span style={{ border: 0 }}>(ii)</span>
                        <span style={{ fontSize: "0.875em", border: 0 }}>
                            During the Term and subject to the provisions of this Agreement, and
                            unless otherwise specified by PSP, the Retail Service Provider shall at
                            its Retail Outlet:
                        </span>
                        <ul style={{ border: 0 }}>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ fontSize: "0.9375em", border: 0 }}>(a)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    assist Consumers in utilizing IRCTC Services;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ fontSize: "0.9375em", border: 0 }}>(b)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    provide such services to Consumers as is intimated in writing by
                                    PSP; and/or
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ fontSize: "0.9375em", border: 0 }}>(c)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    undertake such other activities as instructed by the PSP from time
                                    to time.
                                </span>
                            </li>
                        </ul>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <span style={{ border: 0 }}>(iii)</span>
                        <span style={{ fontSize: "0.875em", border: 0 }}>
                            RSP acknowledges and agrees that its appointment is subject to its
                            continuance compliance with the provisions of this Agreement.
                        </span>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <span style={{ border: 0 }}>(iv)</span>
                        <span style={{ fontSize: "0.875em", border: 0 }}>
                            PSP shall, in order to ensure continuous provisioning of IRCTC Services
                            on Website / Platform, require RSP to maintain an amount in advance with
                            PSP either directly or with the assistance of BP from time to time. PSP
                            may restrict certain/all transactions on Website / Platform in relation
                            to the IRCTC Services on account of insufficiency of such advance amount
                            as is required for providing IRCTC Services. The determination of such
                            sufficiency / insufficiency shall be at Company’s sole discretion.
                        </span>
                        <span style={{ fontSize: "0.875em", border: 0 }}>
                            <u style={{ border: 0 }}>Renewal of IRCTC ID:</u> PSP shall issue a
                            notification to the RSP for renewal of its IRCTC ID 75 (seventy-five)
                            days prior to the date of its expiry. RSP shall have the option to,
                            within a period of 15 (fifteen) days of such notification, either: (a)
                            decline such renewal; or (b) make payment of the yearly renewal fees for
                            renewal of IRCTC ID. The RSP shall be deemed to have agreed to renewal
                            of its IRCTC ID in the event that it fails to decline such renewal
                            within the aforementioned 15 (fifteen) day period. In the event that the
                            RSP fails to exercise either of the options during the aforementioned 15
                            (fifteen) day period, PSP shall be authorized to deduct the yearly
                            renewal fees from the advance amount maintained by the RSP with the PSP
                            during the remaining 60 (sixty) days prior to the expiry of the IRCTC ID
                            without any notification to the RSP, and subject to availability of
                            adequate funds in such advance amount. In the event of successful
                            payment of the yearly renewal fees, either by the RSP or by the PSP
                            through deduction from advance amounts maintained by RSP, the IRCTC ID
                            shall be renewed for a further period of 1 (one) year. Upon failure of
                            such payment, the IRCTC ID shall be deactivated at the due date of its
                            expiry, i.e., 75 (seventy-five) days from the first notification. It is
                            hereby clarified that RSP shall continue to have access to its IRCTC ID
                            and IRCTC Services up to the date of its expiry even in the event that
                            it declines renewal of the IRCTC ID not less than 60 (sixty) days in
                            advance of its expiry.
                        </span>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <span style={{ border: 0 }}>(v)</span>
                        <span style={{ fontSize: "0.875em", border: 0 }}>
                            During the Term of this Agreement and subject to the provisions of this
                            Agreement, RSP hereby authorizes the PSP to:
                        </span>
                        <ul style={{ border: 0 }}>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ fontSize: "0.9375em", border: 0 }}>(a)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    deduct the yearly renewal fees paid by the PSP to IRCTC on behalf of
                                    the RSP for the purpose of providing access to the IRCTC Services
                                    from the advance amount maintained by the RSP with the PSP, in the
                                    manner specified in sub-clause (v) above;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ fontSize: "0.9375em", border: 0 }}>(b)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    deduct any penalty/fine imposed by IRCTC on the PSP, in case of any
                                    default and/or failure on the part of the RSP in complying with the
                                    guidelines issued by IRCTC in relation to performance of activities
                                    by the RSP while providing IRCTC Services, from the advance amount
                                    maintained by the RSP with the PSP; and/or
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ fontSize: "0.9375em", border: 0 }}>(c)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    limit/block access to the IRCTC ID of the RSP in case of default
                                    and/or failure on the part of the RSP in complying with the
                                    guidelines issued by IRCTC in relation to performance of activities
                                    by the RSP while providing IRCTC Services or in case of any default
                                    in complying with the instructions given by the PSP from time to
                                    time.
                                </span>
                            </li>
                        </ul>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <span style={{ border: 0 }}>(vi)</span>
                        <span style={{ fontSize: "0.875em", border: 0 }}>
                            RSP agrees and acknowledges that IRCTC and/or PSP shall have the right
                            to limit, restrict or block access to the IRCTC ID of the RSP and/or
                            temporarily or permanently, suspend or terminate the IRCTC ID of the RSP
                            in the event that the RSP does not access IRCTC Services through its
                            IRCTC ID or does not use its IRCTC ID in any manner for a period
                            exceeding 6 (six) months.
                        </span>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <span style={{ border: 0 }}>(vii)</span>
                        <span style={{ fontSize: "0.875em", border: 0 }}>
                            RSP agrees and acknowledges that Company shall have the right to
                            permanently deactivate the IRCTC ID of the RSP in the event that the RSP
                            has not booked any ticket using IRCTC ID for a period of 6 (six) months
                            from the date of registration.
                        </span>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <span style={{ border: 0 }}>(viii)</span>
                        <span style={{ fontSize: "0.875em", border: 0 }}>
                            During the Term of this Agreement, RSP hereby agrees and undertakes to
                            ensure compliance with the guidelines issued by the IRCTC in relation to
                            the performance of the IRCTC Services which are available at{" "}
                            <u>
                                <a
                                    href="https://contents.irctc.co.in/en/Agent_Policy.pdf"
                                    target="_blank"
                                    style={{ color: "rgb(0, 0, 255)", border: 0 }}
                                >
                                    https://contents.irctc.co.in/en/Agent_Policy.pdf
                                </a>
                            </u>
                        </span>
                    </li>
                </ul>
                <p>
                    <strong />
                </p>
                <h4
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: "1.25em",
                        border: 0
                    }}
                >
                    <strong>
                        <span style={{ fontSize: "inherit", border: 0 }}>4.</span>
                        <span style={{ border: 0 }}>FEES AND EXPENDITURE</span>
                    </strong>
                </h4>
                <p />
                <ul
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: 16,
                        border: 0
                    }}
                >
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <strong>
                                <span style={{ fontSize: "inherit", border: 0 }}>4.1</span>
                            </strong>
                        </strong>
                        In consideration of Company making available the Platform, Website and /or
                        Company Services to Retail Partner, it shall be entitled to a service fees{" "}
                        <strong>(“Company Service Fee”)</strong> as may be intimated by Company
                        from time to time. Company shall also be entitled to receive/deduct any
                        other service fee from Retail Partner for any services provided by the
                        Company to Retail Partner as may be intimated by Company from time to
                        time.
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <strong>
                                <span style={{ fontSize: "inherit", border: 0 }}>4.2</span>
                            </strong>
                        </strong>
                        In addition to the limited right to use the available Platform, Website
                        and /or Company Services and depending upon the nature of Services being
                        provided by Retail Partner, Retail Partner may be entitled to commissions,
                        discounts service fees or such other remuneration from Consumers and/or
                        Company (collectively the <strong>“Retail Partner Service Fees”</strong>)
                        as may be intimated by Company from time to time.
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <strong>
                                <span style={{ fontSize: "inherit", border: 0 }}>4.3</span>
                            </strong>
                        </strong>
                        Retail Partner shall be responsible for payment of its own Taxes, of
                        whatever nature, in respect of all sums payable by Company to Retail
                        Partner under this Agreement.
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <strong>
                                <span style={{ fontSize: "inherit", border: 0 }}>4.4</span>
                            </strong>
                        </strong>
                        In relation to certain Company Services, Retailer Partner may be required
                        to maintain such security deposit as may be intimated by Company and/or
                        BPs from time to time.
                    </li>
                </ul>
                <p>
                    <strong />
                </p>
                <h4
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: "1.25em",
                        border: 0
                    }}
                >
                    <strong>
                        <span style={{ fontSize: "inherit", border: 0 }}>5.</span>
                        <span style={{ border: 0 }}>OPERATION OF RETAIL OUTLET</span>
                    </strong>
                </h4>
                <p />
                <ul
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: 16,
                        border: 0
                    }}
                >
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <strong>
                                <span style={{ fontSize: "inherit", border: 0 }}>5.1</span>
                            </strong>
                        </strong>
                        During the Term, Retailer Partner shall render Services by and through
                        such of its officers, employees, agents, representatives and affiliates as
                        it shall designate, from time to time. Notwithstanding anything to the
                        contrary contained in this Agreement, Company shall have the right at any
                        time, to modify, alter, amend and/or discontinue Services, including the
                        manner, procedure, process in which Retail Partner will be required to
                        perform Services and Retail Partner shall be bound by all such
                        modifications, alterations and amendments made by Company.
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <strong>
                                <span style={{ fontSize: "inherit", border: 0 }}>5.2</span>
                            </strong>
                        </strong>
                        It is hereby agreed between the Parties and declared for the avoidance of
                        doubt that Retail Partner shall only market, promote or make available
                        such Company Services as are permitted expressly by Company.
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <strong>
                                <span style={{ fontSize: "inherit", border: 0 }}>5.3</span>
                            </strong>
                        </strong>
                        Company may specify to Retail Partner, the necessary infrastructure and
                        equipment required at the Retail Outlet including but not limited to any
                        minimum area requirements of the Retail Outlet, computer terminals,
                        peripherals attachments, internet and broadband connectivity, mobile
                        device and/or any such device with GPRS connectivity, html enabled, which
                        is sufficient for the purposes of utilizing Software and/or effectively
                        making available Company Services (<strong>“Specifications”</strong>).
                        Retail Partner shall ensure compliance with Specifications at all times at
                        its sole expense. Further, Retail Partner shall at all times during the
                        Term, display Company’s signage, signboards, logos, etc. at a prominent
                        place in the Retail Outlet strictly in accordance with the instructions
                        provided by Company and/or BP in this regard.
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <strong>
                                <span style={{ fontSize: "inherit", border: 0 }}>5.4</span>
                            </strong>
                        </strong>
                        Company may, at its sole discretion and with or without consideration,
                        provide training manuals, technology support, ongoing advice and
                        assistance to Retail Partner and designated employees of Retail Partner
                        including without limitation the provision of brochures, pamphlets,
                        charts, signboards, signage and other materials for the purposes of
                        advertisement, promotion and marketing Company Services. Any materials
                        supplied by Company shall be utilized solely in relation to the provision
                        of Services and Retail Partner shall ensure that such materials are not
                        utilized for any other purpose.
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <strong>
                                <span style={{ fontSize: "inherit", border: 0 }}>5.5</span>
                            </strong>
                        </strong>
                        Company may, in the interest of making available Services to Consumers,
                        allow Retail Partner to provide Services from a location other than a
                        Retail Outlet subject to Applicable Law and rules intimated by Company in
                        this regard.
                    </li>
                </ul>
                <p>
                    <strong />
                </p>
                <h4
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: "1.25em",
                        border: 0
                    }}
                >
                    <strong>
                        <span style={{ fontSize: "inherit", border: 0 }}>6.</span>
                        <span style={{ border: 0 }}>TERM AND TERMINATION</span>
                    </strong>
                </h4>
                <p />
                <ul
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: 16,
                        border: 0
                    }}
                >
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <strong>
                                <span style={{ fontSize: "inherit", border: 0 }}>6.1</span>
                            </strong>
                        </strong>{" "}
                        <u style={{ border: 0 }}>Term</u>
                    </li>
                </ul>
                <ul
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: "1.125em",
                        border: 0
                    }}
                >
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <span style={{ border: 0 }}>(i)</span>
                        <span style={{ fontSize: "0.875em", border: 0 }}>
                            This Agreement will be effective from Effective Date and shall remain
                            valid for a period of 5 (five) years unless terminated earlier in
                            accordance with Clause 6.2 of this Agreement (“Term”).
                        </span>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <span style={{ border: 0 }}>(ii)</span>
                        <span style={{ fontSize: "0.875em", border: 0 }}>
                            Upon the expiry of Term and subject to satisfactory performance of
                            Retail Partner’s obligations under the Agreement, this Agreement may be
                            renewed at Company’ option, on such terms and conditions as may be
                            applicable at the time of such renewal. Company may exercise its right
                            of renewal by giving a notice in writing to Retail Partner 30 (thirty)
                            days prior to the completion of the Term.
                        </span>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <span style={{ border: 0 }}>(iii)</span>
                        <span style={{ fontSize: "0.875em", border: 0 }}>
                            If Company decides to renew this Agreement, the Parties shall forthwith
                            execute an agreement on the same terms and conditions and/or such terms
                            and conditions as may be applicable at the time of such renewal. Retail
                            Partner shall ensure that the said agreement is renewed from time to
                            time and Retail Partner agrees and acknowledges that Company shall not
                            be responsible/liable in any manner whatsoever, if Agreement is not
                            renewed and the Retail Partner continues to do business during such
                            period wherein, there is no valid agreement between the Parties.
                        </span>
                    </li>
                </ul>
                <ul
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: 16,
                        border: 0
                    }}
                >
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <strong>
                                <span style={{ fontSize: "inherit", border: 0 }}>6.2</span>
                            </strong>
                        </strong>{" "}
                        <u style={{ border: 0 }}>Termination</u>
                    </li>
                </ul>
                <ul
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: "1.125em",
                        border: 0
                    }}
                >
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <span style={{ border: 0 }}>(i)</span>
                        <span style={{ fontSize: "0.875em", border: 0 }}>
                            During the period commencing from the Effective Date and expiring on [24
                            (twenty-four) months] from the Effective Date, (the "Lock-in Period")
                            Retail Partner cannot terminate this Agreement.
                        </span>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <span style={{ border: 0 }}>(ii)</span>
                        <span style={{ fontSize: "0.875em", border: 0 }}>
                            After the expiry of Lock-in Period, Retail Partner may terminate this
                            Agreement by giving a 90 (ninety) days’ written notice to Company.
                        </span>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <br />
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <span style={{ border: 0 }}>(iii)</span>
                        <span style={{ fontSize: "0.875em", border: 0 }}>
                            Company shall have the right to terminate this Agreement immediately
                            upon the occurrence of an <strong>“Event of Default”</strong>. For
                            purposes of this Agreement, the term <strong>“Event of Default”</strong>{" "}
                            shall have occurred if:
                        </span>
                        <ul style={{ border: 0 }}>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ fontSize: "0.9375em", border: 0 }}>(a)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    if there is a Material Breach by Retail Partner that remains uncured
                                    even after the expiry of 15 (fifteen) Business Days after the date
                                    of a written notice issued by the Company of such occurrence;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ fontSize: "0.9375em", border: 0 }}>(b)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    if Retail Partner commits any fraud, negligence, misconduct, or is
                                    otherwise engaged in any fraudulent or illegal activity;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ fontSize: "0.9375em", border: 0 }}>(c)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    if the agreement between Company and the BP is terminated; or
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ fontSize: "0.9375em", border: 0 }}>(d)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    if an order is made by a court of competent jurisdiction, or a
                                    resolution is passed, for the liquidation, bankruptcy, insolvency or
                                    administration of Retail Partner or a notice of appointment of an
                                    administrator of Retail Partner is filed with a court of competent
                                    jurisdiction.
                                </span>
                            </li>
                        </ul>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <span style={{ border: 0 }}>(iv)</span>
                        <span style={{ fontSize: "0.875em", border: 0 }}>
                            In addition to the foregoing, Company may terminate this Agreement at
                            any time by giving a 30 (thirty) days written notice to Retail Partner.
                        </span>
                    </li>
                </ul>
                <ul
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: 16,
                        border: 0
                    }}
                >
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <span style={{ fontSize: "inherit", border: 0 }}>6.3</span>
                        </strong>
                        <span style={{ border: 0 }}>
                            Without prejudice to anything else contained in this Agreement, In the
                            event of termination of the Agreement under Clause 6.2.(a) of 6.2.(b),
                            the Company reserves the right to take such civil or criminal legal
                            action against the Retail Partner as may be appropriate or required at
                            the discretion of the Company.
                        </span>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <span style={{ fontSize: "inherit", border: 0 }}>6.4</span>
                        </strong>
                        <span style={{ border: 0 }}>
                            The rights and obligations of the Parties under this Agreement, which
                            either expressly or by their nature survive the termination of this
                            Agreement, shall not be extinguished by termination of this Agreement.
                        </span>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <span style={{ fontSize: "inherit", border: 0 }}>6.5</span>
                        </strong>
                        <span style={{ border: 0 }}>
                            The termination of this Agreement in any of the circumstances aforesaid
                            shall not in any way affect or prejudice any right accrued to any Party
                            against the other Parties, prior to such termination.
                        </span>
                    </li>
                </ul>
                <p>
                    <strong />
                </p>
                <h4
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: "1.25em",
                        border: 0
                    }}
                >
                    <strong>
                        <span style={{ fontSize: "inherit", border: 0 }}>7.</span>
                        <span style={{ border: 0 }}>CONSEQUENCES OF TERMINATION</span>
                    </strong>
                </h4>
                <p />
                <ul
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: 16,
                        border: 0
                    }}
                >
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <strong>
                                <span style={{ fontSize: "inherit", border: 0 }}>7.1</span>
                            </strong>
                        </strong>{" "}
                        Upon termination of this Agreement for any reason stated in Clause 6
                        above, the Retail Partner shall:
                        <ul style={{ fontSize: "1.125em", border: 0 }}>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(i)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    immediately cease to utilize, promote, market or advertise Company’s
                                    products and/or services including but not limited to Company
                                    Services;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(ii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    immediately discontinue to utilize any Intellectual Property
                                    including but not limited to logo and name of Company and shall
                                    immediately hand over any and all copies or documentation of such
                                    Intellectual Property;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(iii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    immediately return to Company all Confidential Information provided
                                    to Retail Partner under the Agreement including but not limited to
                                    all information and data with respect to BP and Consumers;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(iv)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    immediately return to Company originals and copies of any and all
                                    materials provided to Retail Partner pursuant to this Agreement or
                                    in the course of provision of Services, including any publicity and
                                    marketing materials in its possession;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(v)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    immediately discontinue and cease to use Platform and/or any
                                    Software provided by Company and shall hand over any copies or
                                    documentation of Software and shall purge Software or cause it to be
                                    purged from all human and machine-readable media (or other memory
                                    devices);
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(vi)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    provide remote access to Company to disable any software that
                                    Company and/or BP had installed;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(vii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    immediately remove all signboards, banners, glow signboards and all
                                    such material which indicates any association with Company from
                                    Retail Outlets, its office and any other premises; and
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(viii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    confirm in writing to the Company of having complied with the
                                    provisions of this Clause 7.1.
                                </span>
                            </li>
                        </ul>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <strong>
                                <span style={{ fontSize: "inherit", border: 0 }}>7.2</span>
                            </strong>
                        </strong>
                        Upon notice of termination from either Party, Retail Partner shall perform
                        all the obligations under this Agreement during the notice period. Any
                        waiver of the forgoing obligation should be at the absolute discretion of
                        the Company and such waiver shall only be valid if given in writing by the
                        Company.
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <strong>
                                <span style={{ fontSize: "inherit", border: 0 }}>7.3</span>
                            </strong>
                        </strong>
                        Retail Partner hereby agrees and undertakes that it shall grant Company,
                        its employees or agents, access to its offices/place of business and
                        information technology systems for a period of 60 (sixty) days after
                        termination for the verification of its compliance under Clause 7 of the
                        Agreement.
                    </li>
                </ul>
                <p>
                    <strong />
                </p>
                <h4
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: "1.25em",
                        border: 0
                    }}
                >
                    <strong>
                        <span style={{ fontSize: "inherit", border: 0 }}>8.</span>
                        <span style={{ border: 0 }}>
                            REPRESENTATIONS, WARRANTIES AND UNDERTAKINGS
                        </span>
                    </strong>
                </h4>
                <p />
                <ul
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: 16,
                        border: 0
                    }}
                >
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <strong>
                                <span style={{ fontSize: "inherit", border: 0 }}>8.1</span>
                            </strong>
                        </strong>{" "}
                        Retail Partner represents and warrants to Company that the execution and
                        delivery by Retail Partner of this Agreement does not, and the performance
                        by Retail Partner of his obligations hereunder will not, with or without
                        the giving of notice or the passage of time, or both:
                        <ul style={{ fontSize: "1.125em", border: 0 }}>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(i)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    violate any judgment, writ, injunction, or order of any court,
                                    arbitrator, or governmental agency, applicable to Retail Partner;
                                    and / or
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(ii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    conflict with result in the breach of any provisions of or the
                                    termination of, or constitute a default under, any agreement to
                                    which the Retail Partner is a party or by which Retail Partner is or
                                    may be bound, including, without limitation, any non-competition,
                                    non-solicitation agreement or similar agreement.
                                </span>
                            </li>
                        </ul>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <strong>
                                <span style={{ fontSize: "inherit", border: 0 }}>8.2</span>
                            </strong>
                        </strong>
                        Retail Partner further represents and warrants that it fully and
                        completely understands this Agreement and the financial requirements and
                        risks associated with the same and that:
                        <ul style={{ fontSize: "1.125em", border: 0 }}>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(i)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it has the power, financial and legal capacity to execute, deliver
                                    and perform its obligations under this Agreement and all necessary
                                    corporate, shareholder and other actions have been validly obtained
                                    to authorize such execution, delivery and performance, and this
                                    Agreement constitutes its legal, valid and binding obligation,
                                    enforceable against it in accordance with its terms; and
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(ii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it has engaged in negotiations with Company and has either consulted
                                    with an attorney of his choice or has had ample opportunity to do so
                                    and is fully satisfied with the opportunity it has had.
                                </span>
                            </li>
                        </ul>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <strong>
                                <span style={{ fontSize: "inherit", border: 0 }}>8.3</span>
                            </strong>
                        </strong>
                        Retail Partner hereby represents, agrees and undertakes that:
                        <ul style={{ fontSize: "1.125em", border: 0 }}>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(i)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it shall at all times ensure compliance with Applicable Laws, the
                                    provisions of this Agreement, Company Rules and instructions
                                    provided by Company from time to time;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(ii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    the information provided in the RAF is complete and accurate and if
                                    there is any change in such information it shall inform the Company
                                    immediately of such change;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(iii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it shall not provide any Services or collect any amounts from
                                    Consumers during the time Company Services, Website and/or Platform
                                    are unavailable to Retail Partner at any time and/or for any reason
                                    whatsoever.
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(iv)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it shall not at any point undertake or facilitate, any cash
                                    collection or payment through any officer, employee, agent of the
                                    Company and that it is fully aware that it shall be solely
                                    responsible for any such cash collection or settlement and shall
                                    indemnify and hold harmless the Company from any loss arising out or
                                    in relation to such cash transaction/collection;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    <em>
                                        <b>Retail Outlets</b>
                                    </em>
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(v)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    at all times during the term of the Agreement, the Retail Outlets
                                    shall comply with the Specifications;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(vi)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    all Services shall be provided solely through the Platform and/or
                                    Website or are recorded therein and no offline transactions are
                                    undertaken and/or no such fake/ false receipts are issued by the
                                    Retail Partner for any transaction done by the Retail Partner which
                                    are not transacted through Platform and/or Website;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(vii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    at all times during the Term of the Agreement, it shall ensure that
                                    no other services of the same and/or similar nature to Services that
                                    Retail Partner is authorized to provide by Company;[that the Retail
                                    Outlet is utilized solely for the purpose of rendering the Services
                                    in accordance with the Agreement]
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(viii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it shall ensure that and shall not cause or permit to be made
                                    available in the Retail Outlet: (a) any material that may not be
                                    made available to the public under Applicable Law, including without
                                    limitation any vulgar, obscene, pornographic, misleading,
                                    defamatory, libelous, offensive, derogatory, threatening, harassing,
                                    abusive or violent content and/or (b) carry out any activity that is
                                    not permitted under Applicable Laws or is reasonably considered to
                                    be immoral or against public interest;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(ix)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    at all times during the Term of this Agreement, it shall bear all
                                    costs and/or incidental expenses including without limitation all
                                    fees for application, license fees, charges and Taxes, in relation
                                    to the provision of Services and/or operating the Retail Outlet;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(x)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    at all times during the Term of this Agreement, the Retail Outlet
                                    shall be open for business during normal business hours, or as
                                    permitted by Applicable Laws or such other time period as Company
                                    may specify from time to time;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(xi)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>Employees</span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(xii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it shall be solely responsible for all such personnel employed by
                                    him, including payment of wages, making of contributions under
                                    Applicable Laws such as the Employees Provident Fund and
                                    Miscellaneous Provisions Act, 1952, the Employees State Insurance
                                    Act, 1948, etc.;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(xiii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it acknowledges that it shall be solely responsible for all acts and
                                    omissions of its employees, subordinates, agents, aforesaid
                                    assignees or other personnel engaged by the Retail Partner;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(xiv)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it shall make correct and accurate representations of the services
                                    offered by Company including Company Services to Consumers;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(xv)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it shall not at any point and to any Person make any communication
                                    regarding the services offered by Company unless authorized by
                                    Company in writing;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(xvi)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it shall, unless otherwise intimated in writing by the Company,
                                    follow the instructions provided by BPs with respect to Company
                                    Services;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(xvii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it shall immediately inform to Company and the relevant BP of any
                                    circumstances that can cause damage to the business, goodwill and
                                    reputation of Company;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(xviii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it shall work exclusively for the Company during the Term and shall
                                    not provide its services to any other Person, unless so permitted by
                                    Company in writing;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(xix)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    during the Term and for a period of 1 (one) year thereafter, it
                                    shall not, directly or indirectly, either individually or through
                                    any Person (including through its employees, Affiliates or relatives
                                    or in a firm where the Retail Partner or any relative or nominee of
                                    the Retail Partner is a partner, or in any company where the Retail
                                    Partner or any relative or nominee of the Retail Partner is a
                                    director or shareholder):
                                </span>
                                <ul style={{ border: 0 }}>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ fontSize: "0.9375em", border: 0 }}>(a)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            be appointed as a distributor/ strategic business partner
                                            /retail partner for any other Person that is carrying out any
                                            business that is same or similar to Competing Business or is in
                                            competition to business carried by the Company and/or the
                                            Related Entities; and/or
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ fontSize: "0.9375em", border: 0 }}>(b)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            engage or be interested (as a stockholder, director, officer,
                                            trustee, consultant, or otherwise), either individually or
                                            through any Person, in any other business, which undertakes,
                                            anywhere in India or elsewhere, any activity, which is
                                            competitive with Company’s and/or Related Entities’ business
                                            activity without the prior written consent of Company, which
                                            Company may withhold at its sole discretion.
                                        </span>
                                    </li>
                                </ul>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(xx)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it shall not encroach upon / solicit business in the territories
                                    assigned to other business partners, distributors or retailers of
                                    the Company. In the event of disputes between the Retail Partners
                                    and any such other business partner of Company, such disputes shall
                                    be resolved by an officer appointed by Company in this regard, whose
                                    decision shall be final and binding;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(xxi)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it shall maintain accurate and proper accounts of all transactions
                                    between BP and itself in the form prescribed and updated by Company
                                    from time to time;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(xxii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it shall observe proper ethics and transparency in all its actions
                                    in the course of provision of the Services and shall not, in any
                                    circumstances, take any action or make any statement that may
                                    mislead any Person;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(xxiii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it shall not provide any discounts on the prices fixed for various
                                    products/services by Company except with Company’s prior written
                                    consent;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(xxiv)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it shall promote the sale of Company’s products/services in
                                    accordance with the publicity and marketing guidelines issued by
                                    Company from time to time;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(xxv)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it shall make all efforts to settle any disputes that may arise
                                    between itself and Consumers amicably and in the event any such
                                    dispute is referred to a consumer forum or other competent
                                    authority, shall provide all assistance in the settlement of the
                                    dispute;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(xxvi)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it shall be solely responsible for and hereby undertakes to strictly
                                    comply with all Applicable Laws in connection with the provision of
                                    Services and shall obtain and maintain in full force and effect all
                                    Approvals, registrations required under Applicable Laws for the
                                    operation of the business and provision of the Services, including
                                    the exhibition of sign boards and/or neon/advertising signs, etc.,
                                    at its expense;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(xxvii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it shall ensure regular and timely payment and deposit of all Taxes
                                    as applicable from time to time with the relevant authorities;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(xxviii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it shall obtain Company’s prior written approval for any change in
                                    its constitution and/or location of its Retail Outlet and other
                                    place of business;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(xxix)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it shall use its best endeavours and take such steps as Company may
                                    reasonably require to ensure that its management and staff keep
                                    confidential the contents of this Agreement and/or all information
                                    they obtain about Company’s business which is not available to the
                                    general public;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(xxx)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it is aware and acknowledge that the services provided by the
                                    Company including Company Services is provided on an{" "}
                                    <strong>“as is”</strong> and <strong>“as available”</strong> basis
                                    and that the use of Company Services by Retail Partners and/or
                                    Consumers is at the Retail Partner’s own risk;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(xxxi)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it is aware and acknowledge that the Company does not warrant,
                                    endorse, guarantee, or assume responsibility for any product or
                                    service advertised or offered by a third party including the
                                    Financial Services being provided by various service providers
                                    through Company Services or Platform or any hyperlinked website or
                                    service; and
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(xxxii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    it shall not, without Company’s prior written approval, either on
                                    its invoices, letterheads or any other place or by any other means,
                                    orally or in writing, make any statement or representation,
                                    calculated or liable to induce others to believe that the Retail
                                    Partner is the agent of Company or do any act, deed or things to
                                    bind Company in any way in dealing with any third party(ies).
                                </span>
                            </li>
                        </ul>
                    </li>
                </ul>
                <p>
                    <strong />
                </p>
                <h4
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: "1.25em",
                        border: 0
                    }}
                >
                    <strong>
                        <span style={{ fontSize: "inherit", border: 0 }}>9.</span>
                        <span style={{ border: 0 }}>RIGHTS OF THE COMPANY</span>
                    </strong>
                </h4>
                <p />
                <ul
                    style={{
                        textAlign: "start",
                        color: "rgb(30, 30, 30)",
                        //backgroundColor: "rgb(247, 247, 247)",
                        fontSize: 16,
                        border: 0
                    }}
                >
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <strong>
                                <span style={{ fontSize: "inherit", border: 0 }}>9.1</span>
                            </strong>
                            Suspension of Company Services by Company
                        </strong>
                        <ul style={{ fontSize: "1.125em", border: 0 }}>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(i)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    Retail Partner agrees and acknowledges that Company reserves the
                                    right to suspend and/or terminate the provision of Company Services
                                    if Retail Partner and/or BP has:
                                </span>
                                <ul style={{ border: 0 }}>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ fontSize: "0.9375em", border: 0 }}>(a)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            violated or is likely to violate the terms of this Agreement or
                                            any other agreement it has with Company or any of the Company
                                            Rules;
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ fontSize: "0.9375em", border: 0 }}>(b)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            violated or is likely to violate any of the Applicable Law
                                            related to the services provided by it including the Services;
                                            and/or
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ fontSize: "0.9375em", border: 0 }}>(c)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            provided any false, incomplete, inaccurate or misleading
                                            information or otherwise engaged in fraudulent or illegal
                                            conduct.
                                        </span>
                                    </li>
                                </ul>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(ii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    Retail Partner agrees and acknowledges that the Company reserves the
                                    right to suspend and/or terminate the provision of Company Services:
                                </span>
                                <ul style={{ border: 0 }}>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ fontSize: "0.9375em", border: 0 }}>(a)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            if Company is mandated to do so under Applicable Law and
                                            instructions from Governmental Authority;
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ fontSize: "0.9375em", border: 0 }}>(b)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            for recovery of any of its dues under the Agreement;
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ fontSize: "0.9375em", border: 0 }}>(c)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            for any suspected violation of any rules, regulations, orders,
                                            directions, notifications issued by Governmental Authority from
                                            time to time;
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ fontSize: "0.9375em", border: 0 }}>(d)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            for any discrepancy or suspected discrepancy in the
                                            particular(s) or documentation provided by Consumers, Retail
                                            Partner and/or BP;
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ fontSize: "0.9375em", border: 0 }}>(e)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            due to technical failure, modification, up gradation, variation,
                                            relocation, repair, and/or maintenance due to any emergency or
                                            for any technical reasons;
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ fontSize: "0.9375em", border: 0 }}>(f)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            due to any act/omission/failure on part of the service provider
                                            providing the relevant Financial Services; and/or
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ fontSize: "0.9375em", border: 0 }}>(g)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            for any other reason that Company deems appropriate in the best
                                            interests of Business.
                                        </span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <strong>
                                <span style={{ fontSize: "inherit", border: 0 }}>9.2</span>
                            </strong>
                            Right to Set off and Withhold Payment
                        </strong>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        Retail Partner agrees and acknowledges that Company reserves the right at
                        any time to (without notice to Retail Partner) set off and apply any or
                        all sums due and payable by Company to Retail Partner under this
                        Agreement, and/or any or all sums of money held in accounts with Company
                        and/or BP against:
                        <ul style={{ fontSize: "1.125em", border: 0 }}>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(i)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    any or all sums due and payable by Retail Partner to Company under
                                    this Agreement;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(ii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    the amount of any liability incurred by Retail Partner against
                                    Company under this Agreement;
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(iii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    any amount erroneously paid to Retail Partner by Company and/or BP;
                                    and/or
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(iv)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    any statutory liability of Retail Partner including payment of
                                    applicable Taxes that Retail Partner has failed to pay to the
                                    relevant Governmental Authorities.
                                </span>
                            </li>
                        </ul>
                    </li>
                    <li style={{ fontSize: "1.125em", border: 0 }}>
                        <strong>
                            <strong>
                                <span style={{ fontSize: "inherit", border: 0 }}>9.3</span>
                            </strong>
                            Data Collection and Privacy
                        </strong>
                        <ul style={{ fontSize: "1.125em", border: 0 }}>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(i)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    Retail Partner shall not collect and /or share any data with respect
                                    to the Consumers for itself or any other third party. Retail Partner
                                    agrees acknowledges that Company shall be the sole owner of all data
                                    including Consumer data collected /generated under any transaction
                                    utilizing the Platform and/or the Company Services; and Company
                                    shall be free to share such consumer data with Related Entities.
                                </span>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(ii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    In addition to the foregoing, the Parties agree and acknowledge that
                                    the Company may collect financial and other data from BPs,
                                    Consumers, Retail Partners and may utilize the same for undertaking
                                    a credit check through agencies such as Credit Information Bureau
                                    (India) Limited for the purpose of recommending them for suitable
                                    credit facilities to be provided by financial institutions. In
                                    relation to the same, Retail Partner:
                                </span>
                                <ul style={{ border: 0 }}>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ fontSize: "0.9375em", border: 0 }}>(a)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            undertakes to assist the Company in collection of such financial
                                            data;
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ fontSize: "0.9375em", border: 0 }}>(b)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            undertakes to keep such data as a confidential; and
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ fontSize: "0.9375em", border: 0 }}>(c)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            agrees and acknowledge that Company is the sole owner of such
                                            financial data and that such financial data would be a
                                            Confidential Information for the purpose of this Agreement.
                                        </span>
                                    </li>
                                </ul>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <span style={{ border: 0 }}>(iii)</span>
                                <span style={{ fontSize: "0.875em", border: 0 }}>
                                    All of the information collected by Company Retail Partner is
                                    subject to the privacy policy available at &nbsp;
                                    <u>
                                        <a
                                            href="#"
                                            style={{ color: "rgb(0, 0, 255)", border: 0 }}
                                        >
                                            Privacy Policy
                                        </a>
                                    </u>
                                </span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>
                            <h4 style={{ fontSize: "1.25em", border: 0 }}>
                                <span style={{ fontSize: "inherit", border: 0 }}>10.</span>
                                <span style={{ border: 0 }}>
                                    NON SOLICITATION AND NON DISCLOSURE COVENANTS
                                </span>
                            </h4>
                        </strong>
                        <ul style={{ border: 0 }}>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>10.1</span>
                                    </strong>
                                </strong>
                                Retail Partner acknowledges that its services hereunder are of a
                                special, unique character, and its strategic business partnership with
                                the Company places it in a position of confidence and trust with
                                customers, suppliers, and other persons and entities with whom the
                                Company have a business relationship.
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>10.2</span>
                                    </strong>
                                </strong>
                                Retail Partner further acknowledges that the rendering of services
                                under this Agreement will likely require the disclosure to the Retail
                                Partner of Confidential Information including Trade Secrets. As a
                                consequence, Retail Partner agrees that it is reasonable and necessary
                                for the protection of the goodwill and legitimate business interests
                                of the Company that the Retail Partner makes the covenants contained
                                in this Clause 10 and that such covenants are a material inducement
                                for the Company to enter into this Agreement, and that the covenants
                                are given as an integral part of this Agreement.
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>10.3</span>
                                    </strong>
                                </strong>
                                <u style={{ border: 0 }}>Non solicitation Covenants:</u> Retail
                                Partner agrees that during the Term of the Agreement and 1(one) year
                                after the termination of the Agreement, it will not engage in the
                                following acts:
                                <ul style={{ fontSize: "1.125em", border: 0 }}>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ border: 0 }}>(i)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            directly or indirectly assist, promote or encourage any other
                                            Retail Partner, Consumer existing or potential employees,
                                            customers, clients, or vendors of the Company or any other
                                            Retail Partner, as well as any other parties which have a
                                            business relationship with the Company to terminate,
                                            discontinue, or reduce the extent of their relationship with
                                            Company;
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ border: 0 }}>(ii)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            directly or indirectly offer employment to, enter into a
                                            contract for the services of, or attempt to solicit or seek to
                                            entice away from the Company any individual who is at the time
                                            of the offer: (a) a director, officer or employee with the
                                            Company and its Affiliates; (b) client of the Company and/or its
                                            affiliates; and/or (c) or procure or facilitate the making of
                                            any such offer or attempt by any other Person.
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ border: 0 }}>(iii)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            disparage the Company, any Related Entities, and/or any
                                            shareholder, director, officer, employee, or agent of the
                                            Company or any Related Entity and/or;
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ border: 0 }}>(iv)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            engage in any practice, the purpose of which is to evade the
                                            provisions of this Clause 10 or commit any act which adversely
                                            affects the Company, any Related Entity, or their respective
                                            businesses.
                                        </span>
                                    </li>
                                </ul>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>10.4</span>
                                    </strong>
                                </strong>{" "}
                                <u style={{ border: 0 }}>Disclosure of Confidential Information:</u>{" "}
                                Retail Partner acknowledges that the Confidential Information and all
                                other confidential or proprietary information with respect to the
                                business and operations of the Company and Related Entities are
                                valuable, special, and unique assets of the Company. Accordingly,
                                Retail Partner agrees not to, at any time whatsoever either during or
                                after the term of this Agreement disclose, directly or indirectly, to
                                any Person, use or authorize any Person to use, any Confidential
                                Information without the prior written consent of the Company.
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>10.5</span>
                                    </strong>
                                </strong>
                                <u style={{ border: 0 }}>
                                    Prevention of Premature Disclosure of Confidential Information and
                                    Trade Secrets:
                                </u>{" "}
                                Retail Partner agrees and acknowledges that, because the success of
                                the Company is heavily dependent upon maintaining the secrecy of the
                                Company’s Confidential Information and Trade Secrets and preventing
                                the premature public disclosure of the Company’s proprietary
                                information and technology including its Confidential Information and
                                Trade Secrets, the Retail Partner agrees to use his best efforts and
                                highest degree of care, diligence, and prudence to ensure that no
                                Confidential Information, Trade Secret prematurely leaks or otherwise
                                prematurely makes its way into the public domain or any public forum,
                                including, without limitation, into any trade publications, internet
                                chat rooms, social media platforms or other similar forums.
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>10.6</span>
                                    </strong>
                                </strong>
                                In the event that the Retail Partner becomes aware of any premature
                                leak of Confidential Information or Trade Secret or becomes aware of
                                any circumstances creating a risk of such a leak, the Retail Partner
                                shall immediately inform the management of the Company, of such leak
                                or of such circumstances.
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>10.7</span>
                                    </strong>
                                </strong>
                                <u style={{ border: 0 }}>
                                    Use, Removal and Return of Proprietary Items
                                </u>
                                <ul style={{ fontSize: "1.125em", border: 0 }}>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ border: 0 }}>(i)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            Retail Partner shall utilize the Proprietary Items only to the
                                            limited extent of undertaking the Services in accordance with
                                            the terms of this Agreement and as instructed from the Company
                                            from time to time.
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ border: 0 }}>(ii)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            Retail Partner shall not and ensure that the Consumers shall not
                                            utilize the Platform and/or Company Services in any manner that
                                            is not explicitly permitted in the Agreement.
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ border: 0 }}>(iii)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            Retail Partner shall not and ensure that the Consumers shall not
                                            transmit by any means, electronic or otherwise, any Proprietary
                                            Items.
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ border: 0 }}>(iv)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            Retail Partner recognizes that, as between the Company and the
                                            Retail Partner, all of the Proprietary Items, whether or not
                                            developed with the assistance of the Retail Partner, are the
                                            exclusive property of the Company. Immediately, upon termination
                                            of this Agreement (regardless of the reason for termination), or
                                            upon the request of the Company during the term of this
                                            Agreement, the Retail Partner shall return to the Company all
                                            the Proprietary Items, Confidential Information, Trade Secret or
                                            any part thereof in Retail Partner’s possession or subject to
                                            Retail Partner’s control, and the Retail Partner shall not
                                            retain any copies, abstracts, sketches, or other physical
                                            embodiment of any of the Proprietary Items, Confidential
                                            Information, Trade Secret or any part thereof.
                                        </span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>
                            <h4 style={{ fontSize: "1.25em", border: 0 }}>
                                <span style={{ fontSize: "inherit", border: 0 }}>11.</span>
                                <span style={{ border: 0 }}>INTELLECTUAL PROPERTY</span>
                            </h4>
                        </strong>
                        <ul style={{ border: 0 }}>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>11.1</span>
                                    </strong>
                                </strong>
                                During the Term, Company hereby grants Retail Partner a limited
                                non-exclusive, non-transferable, non sub-licensable, non assignable,
                                right to use the Platform and Software solely for the purpose of
                                provision of the Services as contemplated under this Agreement, which
                                may be revoked by Company at any time and without assigning any reason
                                at its discretion.
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>11.2</span>
                                    </strong>
                                </strong>
                                Retail Partner shall not sublicense, assign or otherwise transfer the
                                Software to any Person and is expressly prohibited from distributing,
                                sublicensing, assigning, transferring or otherwise, the Software, or
                                other technical documentation pertaining thereto, or any portions
                                thereof in any form.
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>11.3</span>
                                    </strong>
                                </strong>
                                Retail Partner may utilize any third party software other than the
                                Software only with Company’s prior written consent. Further, the
                                Retail Partner shall ensure that such third party software is validly
                                licensed and installed.
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>11.4</span>
                                    </strong>
                                </strong>
                                Further, Subject to the provisions of this Agreement, Company hereby
                                grants a temporary, limited, revocable, conditional, non-exclusive,
                                non-sub-licensable, non-transferable right to use the Intellectual
                                Property, during the Term and solely for the purpose of and to the
                                extent necessary for providing the Services and in accordance with the
                                directions and specifications as, from time to time, communicated or
                                approved by Company in writing.
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>11.5</span>
                                    </strong>
                                </strong>
                                Company shall retain all rights over all its Intellectual Property,
                                including the Software and/or Platform, its name and logo and all
                                rights relating to the publicity and marketing materials. Retail
                                Partner hereby acknowledges that:
                                <ul style={{ border: 0 }}>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ border: 0 }}>(i)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            the execution of this Agreement does not amount to any transfer
                                            to it of any Intellectual Property rights held by Company prior
                                            to the execution of this Agreement, nor does this Agreement in
                                            any way limit Company’s rights over its Intellectual Property,
                                            including the right to license to others; and
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ border: 0 }}>(ii)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            any and all goodwill arising from Retail Partner’s use of
                                            Company’s Intellectual Property shall inure exclusively to
                                            Company without any compensation.
                                        </span>
                                    </li>
                                </ul>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>11.6</span>
                                    </strong>
                                </strong>
                                Retail Partner hereby agrees and undertakes that at any time:
                                <ul style={{ border: 0 }}>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ border: 0 }}>(i)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            it shall not take any action, which shall or may impair
                                            Company’s right, title or interest in the Intellectual Property,
                                            or create any right, title or interest therein or thereto,
                                            adverse to that of Company;
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ border: 0 }}>(ii)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            it shall not use the Intellectual Property together with any
                                            other mark or marks or any other part of trademark;
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ border: 0 }}>(iii)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            it shall not misuse or permit such unauthorized use of the
                                            Intellectual Property;
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ border: 0 }}>(iv)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            It shall use the Intellectual Property only in the form and
                                            manner stipulated by Company from time to time and shall observe
                                            any directions given by Company from time to time, including as
                                            to the colours and size of the representations of the logo, the
                                            manner and disposition on any printed matter including the
                                            signage on the interior and exterior of the Retail Partner’s
                                            office premises and any accompanying leaflets, brochures or
                                            other advertising materials prepared by the Retail Partner etc.;
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ border: 0 }}>(v)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            it shall not use the Intellectual Property that is not
                                            previously provided for by Company without Company’s prior
                                            written consent;
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ border: 0 }}>(vi)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            it shall bring to Company’s notice all cases of infringement or
                                            passing off of Company’s Intellectual Property or registration
                                            or attempted registration of the same or of any other
                                            intellectual property similar thereto. In the event Company
                                            undertakes any opposition to or any action to restrain or punish
                                            such act or acts, the Retail Partners agrees to cooperate fully
                                            and freely with Company in the same. If required by Company, the
                                            Retail Partner shall permit Company to undertake such opposition
                                            or action in the name of the Retail Partner. The costs of any
                                            such action shall be borne by the Parties in such proportion as
                                            may be mutually agreed upon; and
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ border: 0 }}>(vii)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            it shall render to Company all assistance in connection with any
                                            matter pertaining to the protection of the Intellectual Property
                                            whether in courts, before administrative agencies, within or
                                            without India, or otherwise.
                                        </span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>
                            <h4 style={{ fontSize: "1.25em", border: 0 }}>
                                <span style={{ fontSize: "inherit", border: 0 }}>12.</span>
                                <span style={{ border: 0 }}>INDEMNIFICATION</span>
                            </h4>
                        </strong>
                        <ul style={{ border: 0 }}>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                Retail Partner shall indemnify, defend and hold Company and Related
                                Entities harmless (including their respective employees, directors,
                                agents, affiliates and representatives) from and against any and all
                                claims, costs, losses, damages, judgments, tax assessments, penalties,
                                interest and expenses (including without limitation attorneys’ fees)
                                arising out of any claim, action, audit, investigation, inquiry, or
                                other proceeding instituted by a person or entity that arises or
                                relates to:
                                <ul style={{ fontSize: "1.125em", border: 0 }}>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ border: 0 }}>(i)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            any actual or alleged breach of the Retail Partners’
                                            representations, warranties, or obligations set forth in this
                                            Agreement, including without limitation any violation of Company
                                            Rules;
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ border: 0 }}>(ii)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            wrongful or improper use of Company’s technologies and
                                            Intellectual Property;
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ border: 0 }}>(iii)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            violation of any third-party right, including without limitation
                                            any right of privacy, publicity rights or intellectual property
                                            rights; and/or
                                        </span>
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <span style={{ border: 0 }}>(iv)</span>
                                        <span style={{ fontSize: "0.875em", border: 0 }}>
                                            violation of any law, rule or regulation of India or any other
                                            country.
                                        </span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>
                            <h4 style={{ fontSize: "1.25em", border: 0 }}>
                                <span style={{ fontSize: "inherit", border: 0 }}>13.</span>
                                <span style={{ border: 0 }}>ERRORS, INACCURACIES AND OMISSIONS</span>
                            </h4>
                        </strong>
                        <ul style={{ border: 0 }}>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>13.1</span>
                                    </strong>
                                </strong>
                                Retail Partner acknowledges and agrees that occasionally there may be
                                information on the Platform or in the Website that contains
                                typographical errors, inaccuracies or omissions that may relate to
                                product or services descriptions, pricing, promotions, offers,
                                transaction times etc. Company reserve the right to correct any
                                errors, inaccuracies or omissions, and to change or update information
                                or cancel transactions if any information in Website and/or Platform
                                is inaccurate at any time without prior notice.
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>13.2</span>
                                    </strong>
                                </strong>{" "}
                                It is hereby agreed and clarified by the Parties for avoidance of
                                doubt that Company shall not be responsible for any of its obligations
                                under this Agreement including with respect to Company Services due to
                                reasons beyond Company’s control such as down time of servers,
                                viruses, strikes, technical snags, system compatibility, natural
                                calamities, acts of war, terror etc. Retail Partner agrees to not hold
                                the Company liable for any delay or adverse effect caused due to the
                                occurrence of such an event.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>
                            <h4 style={{ fontSize: "1.25em", border: 0 }}>
                                <span style={{ fontSize: "inherit", border: 0 }}>14.</span>
                                <span style={{ border: 0 }}>MISCELLENOUS</span>
                            </h4>
                        </strong>
                        <ul style={{ border: 0 }}>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>14.1</span>
                                    </strong>
                                </strong>{" "}
                                <u style={{ border: 0 }}>Jurisdiction:</u> This Agreement shall
                                subject to the provisions of Clause 14.2, be subject to the exclusive
                                jurisdiction of the courts of Mumbai.
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>14.2</span>
                                    </strong>
                                </strong>{" "}
                                <u style={{ border: 0 }}>Dispute Resolution:</u>Parties shall attempt
                                in good faith to resolve any disputes, differences or claims arising
                                out of or relating to this Agreement promptly by negotiation amongst
                                Retail Partner and Company. Any dispute that is not amicably settled
                                shall be finally settled by arbitration conducted in accordance with
                                the (Indian) Arbitration and Conciliation Act, 1996 (as amended). The
                                arbitration shall be conducted by a sole arbitrator to be appointed
                                mutually by the Parties. The seat and venue of arbitration shall be
                                Mumbai, India. The arbitration shall be conducted in English language
                                and a record of the proceedings shall be maintained in English. The
                                award rendered by the arbitrators shall be final and binding upon the
                                Parties in dispute. This sub-clause 14.2 shall not prevent the Parties
                                from approaching a court of competent jurisdiction for injunctive /
                                interim relief.
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>14.3</span>
                                    </strong>
                                </strong>{" "}
                                <u style={{ border: 0 }}>Entire Agreement:</u>This Agreement, terms
                                and conditions provided in the Website and/or Platform (as amended
                                from time to time) and any documents referred to in it contain the
                                entire agreement between the Parties, and supersedes any prior
                                agreements, representations or communications, written or oral,
                                amongst them relating to its subject matter.
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>14.4</span>
                                    </strong>
                                </strong>{" "}
                                <u style={{ border: 0 }}>Right to Amend:</u> Notwithstanding anything
                                contrary in this Agreement, Company has the right to change or add to
                                the terms of this Agreement at any time, and to change, delete,
                                discontinue, or impose conditions on any feature or aspect of the
                                Company Services and/or Platform with notice that Company in its sole
                                discretion deems to be reasonable in the circumstances, including such
                                notice on the Website or any other website maintained or owned by
                                Company for the purposes of providing Company Services in terms of
                                this Agreement. Any use of the Company Services and/or the Platform
                                after the publication of any such changes shall constitute acceptance
                                of this Agreement by the Retail Partner as modified. However, any
                                dispute that arose before the modification shall be governed by the
                                Agreement (including the binding individual arbitration clause) that
                                was in place when the dispute arose.
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>14.5</span>
                                    </strong>
                                </strong>{" "}
                                <u style={{ border: 0 }}>Relationship:</u> Nothing in this Agreement
                                shall be deemed to constitute a partnership between the parties or
                                constitute either Party the agent and/or the employee of the other for
                                any purpose
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>14.6</span>
                                    </strong>
                                </strong>{" "}
                                <u style={{ border: 0 }}>Partial Invalidity:</u>If any provision of
                                this Agreement or the application thereof to any Person or
                                circumstance shall be invalid or unenforceable to any extent for any
                                reason including by reason of any Applicable Law, the remainder of
                                such provision and/or this Agreement and the application of such
                                provision to persons or circumstances other than those which are held
                                to be invalid or unenforceable shall not be affected thereby, and each
                                remaining provision of this Agreement shall be valid and enforceable
                                to the fullest extent permitted by Applicable Law. Any invalid or
                                unenforceable provision of this Agreement shall be replaced with a
                                provision, which is valid and enforceable and most nearly reflects the
                                original intent of the invalid and unenforceable provision.
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>14.7</span>
                                    </strong>
                                </strong>{" "}
                                <u style={{ border: 0 }}>Notices:</u>
                                <br />
                                <strong>
                                    <u style={{ border: 0 }}>To Company</u>
                                </strong>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                Any amendment, notice, or other communication under this Agreement by
                                Retail Partner to Company shall be sent by personal delivery or
                                courier or email/facsimile or by registered mail at the addresses set
                                forth below (or at such other address or through such other medium as
                                the Company may previously have notified Retail Partner in writing):
                                <ul style={{ border: 0 }}>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>Address:</li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        103 A First Floor Rani Bazaar
                                        <br />
                                        Bikaner Rajasthan INDIA.
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        Grievance officer:
                                    </li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>Anand Gandhi</li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>Email:</li>
                                    <li style={{ fontSize: "1.125em", border: 0 }}>
                                        <u>
                                            <a
                                                href="mailto:info@hpnpay.com"
                                                style={{ color: "rgb(0, 0, 255)", border: 0 }}
                                            >
                                                info@hpnpay.com
                                            </a>
                                        </u>
                                    </li>

                                </ul>
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <u style={{ border: 0 }}>To Retail Partner</u>
                                </strong>
                                <br />
                                <br />
                                Any amendment, notice, or other communication under this Agreement by
                                Company to Retail Partner shall be sent either by: (a) personal
                                delivery or courier or email/facsimile or by registered mail as
                                provided in RAF; (b) communication on the Website and/or Platform; or
                                (c) at such other address or through such other medium as the Company
                                may previously have notified Retail Partner in writing.
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>14.8</span>
                                    </strong>
                                </strong>{" "}
                                <u style={{ border: 0 }}>Waiver:</u>The failure to exercise or delay
                                in exercising a right or remedy provided by this Agreement or by law
                                does not constitute a waiver of the right or remedy or the waiver of
                                other rights or remedies. No single or partial exercise of a right or
                                remedy provided by this Agreement or by law prevents further exercise
                                of the right or remedy or the exercise of another right or remedy. Any
                                waiver must be in writing and signed by the Party sought to be bound.
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>14.9</span>
                                    </strong>
                                </strong>{" "}
                                <u style={{ border: 0 }}>Assignment:</u> This Agreement, or any right
                                or interest herein, shall not be assignable by the Retail Partner
                                except with the prior written consent of the Company. Company shall be
                                free to assign this Agreement, or any right or interest herein, to any
                                Person including but not limited to Related Entities.
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>14.10</span>
                                    </strong>
                                </strong>{" "}
                                <u style={{ border: 0 }}>Rights of Retail Partner:</u> The rights
                                granted to the Retail Partner under this Agreement are non-exclusive,
                                and the Retail Partner acknowledges that Company has and retains all
                                rights except those expressly granted to the Retail Partner under this
                                Agreement.
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>14.11</span>
                                    </strong>
                                </strong>{" "}
                                <u style={{ border: 0 }}>Specific Performance:</u> Retail Partner
                                agree that damages may not be an adequate remedy and that they shall
                                be entitled to an injunction, restraining order, right for recovery,
                                suit for specific performance or such other equitable relief as a
                                court of competent jurisdiction may deem necessary or appropriate to
                                restrain Retail Partner from committing any violation or enforce the
                                performance of the covenants, representations and obligations
                                contained in this Agreement. These injunctive remedies are cumulative
                                and are in addition to any other rights and remedies the Company s may
                                have at Applicable Law or in equity, including a right for damages.
                            </li>
                            <li style={{ fontSize: "1.125em", border: 0 }}>
                                <strong>
                                    <strong>
                                        <span style={{ fontSize: "inherit", border: 0 }}>14.12</span>
                                    </strong>
                                </strong>{" "}
                                <u style={{ border: 0 }}>Survival:</u> The provisions of this
                                Agreement, which by their nature are intended to survive the
                                termination or expiration of this Agreement, including without
                                limitation, the provisions of Clause 10 (Non-compete, Non-solicitation
                                and Non-Disclosure Covenants), Clause 8 (Representations and
                                Warranties), Clause 12 (Indemnity) and Clause 14 (Miscellaneous) shall
                                survive the termination of this Agreement.
                            </li>
                        </ul>
                    </li>
                </ul>
            </>


        </Box>
    )
};

export default TermsAndConditions;