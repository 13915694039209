import React from 'react'
import { Container,Text } from '@chakra-ui/react'
function HpnClub() {
  return (
    <Container>
        <Text fontWeight="bold" m="5rem"> Content Not Found</Text>
    </Container>
  )
}

export default HpnClub