import React from "react";
import { Box, Button, Flex, Image, Input, Text } from "@chakra-ui/react";
import LOGO from "../images/footer/LOGO.svg";
import googlePlayIcon from "../images/login/google-play.png";
import youtubeImage from "../images/login/youtube_4494485.png";
import loginPageImage from "../images/login/Secure login-bro.png";
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';

function Login() {
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }




  return (
    <>
      <div
      
        style={{
          fontSize: "2.5rem",
          textAlign: "center",
          paddingTop: "100px",
          fontWeight: "700",
          color: "gray",
        }}
      >
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 2 }}
        >
          Coming Soon!
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 2 }}
        >
          Exciting things are on the way. Stay tuned!
        </motion.p>
      </div>
    </>
  );
}

export default Login;
