// ContactForm.jsx
import React, { useRef } from 'react';
import { Button, FormControl, FormLabel, Input, VStack } from '@chakra-ui/react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import emailjs from '@emailjs/browser';



function ContactForm () {

  

    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.
      sendForm('service_ld52i5m',
       'template_ypfrodg',
        form.current, 
       'aPIUNzN07c2OHg55q')
        .then((result) => {
            console.log(result.text);
            console.log('message-sent')
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
        console.log('reset')
    };
  
  


  return (
    <>
   <div style={{ marginLeft: "0rem" }}>
  <form ref={form} onSubmit={sendEmail} style={{  margin: 'auto' }}>
    <label style={{ fontWeight: 'normal',  fontSize: '0.975rem' }}>
      Name
    </label>

    <input
      type="text"
      name="to_name"
      style={{
        border: '1px solid #ccc',
        padding: '8px',
        marginBottom: '0.5rem',
        width: '100%',
        fontSize: '0.875rem',
      }}
    />

    <label style={{ fontWeight: 'normal', fontSize: '0.975rem' }}>
      Email
    </label>
    <input
      type="email"
      name="from_name"
      style={{
        border: '1px solid #ccc',
        padding: '8px',
        marginBottom: '0.5rem',
        width: '100%',
        fontSize: '0.875rem',
      }}
    />

    <label style={{ fontWeight: 'normal',  fontSize: '0.975rem' }}>
      Message
    </label>
    <textarea
      name="message"
      style={{
        border: '1px solid #ccc',
        padding: '8px',
        width: '100%',
        fontSize: '0.875rem',
      }}
    />

    <button
      type="submit"
      style={{
        border: '1px solid #ccc',
        padding: '8px',
        cursor: 'pointer',
        background: '#00A6DE',
        marginTop: '1rem',
        marginBottom: '1rem',
        fontWeight: 'bold',
        width: '50%',
        fontSize: '0.875rem',
        borderRadius:"0.5rem",
      }}
    >
      Send
    </button>
  </form>
</div>

  
    </>
  )
}

export default ContactForm
