import React from 'react'
import { Container,Text } from '@chakra-ui/react'

function Events() {
  return (
    <Container>
        <Text fontWeight="bold" m="5rem"> Content Not Found</Text>
    </Container>
  )
}

export default Events