 import { Box, Button, Card, CardBody, CardFooter, CardHeader, Center, Flex, Heading, Image, Input, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import income from "../images/retailer_img/income.png"
import { Link } from 'react-router-dom';

const IncomeCalculator = () => {

    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
      }

   
       

    const bankingData = [

      {
        benefits: "Money Transfer",
        Range: 4000,
        transaction: 1,
        income: 19.2,
        rate: 0.48
      },
      {
        benefits: "Micro ATM",
        Range: 3500,
        transaction: 1,
        income: 7,
        rate: 0.2
      },
      {
        benefits: "Aadhar ATM",
        Range: 3500,
        transaction: 1,
        income: 7,
        rate: 0.2
      },
        // {
        //     benefits: "Mini Statement",
        //     Range: "NaN",
        //     transaction: 1,
        //     income: 1
        // },

        {
          benefits: "DTH Recharge",
          Range: 250,
          transaction: 1,
          income: 5,
          rate: 2
        },
        {
            benefits: "Mobile Recharge",
            Range: 140,
            transaction: 1,
            income: 2.38,
            rate: 1.7
        },
        // {
        //     benefits: "Electricity BP",
        //     Range: 800,
        //     transaction: 1,
        //     income: 0.25,
        //     rate: ''
        // },
        // {
        //     benefits: "Gas BP",
        //     Range: 1000,
        //     rate: '',
        //     transaction: 1,
        //     income: 0.25
        // },
        // {
        //     benefits: "Water Bill",
        //     Range: 700,
        //     rate: '',
        //     transaction: 1,
        //     income: 0.25
        // },
        {
          benefits: "Fast Tag Recharge",
          Range: 1000,
          rate: 0.1,
          transaction: 1,
          income: 1
        },
        {
            benefits: "Credit Card BP",
            Range: 5000,
            rate: 0.5,
            transaction: 1,
            income: 25
        },
       
        
        {
          benefits: "Personal Accident",
          Range: 1000,
          rate: 13.5,
          transaction: 1,
          income: 135
        },
        {
          benefits: "Car Insurance",
          Range: 590,
          rate: 5.5,
          transaction: 1,
          income: 32.45
        },
        {
            benefits: "Bike Insurance",
            Range: 1682,
            rate: 6,
            transaction: 1,
            income: 100.92
        },
        // {
        //     benefits: "IRCTC Non A/C",
        //     Range: 500,
        //     rate: '',
        //     transaction: 1,
        //     income: 20
        // }, {
        //     benefits: "IRCTC A/C",
        //     Range: 1500,
        //     rate: '',
        //     transaction: 1,
        //     income: 40
        // },
        {
          benefits: "OTT Subscription",
          Range: 1000,
          rate: 0.1,
          transaction: 1,
          income: 1
        }, 
        {
            benefits: "Bus Booking",
            Range: 500,
            rate: 6.5,
            transaction: 1,
            income: 32.5
        }, 
        {
          benefits: "Digi Smart Transfer",
          Range: 4000,
            rate: 0.63,
            transaction: 1,
            income: 25.2
          }, 
          {
            benefits: "Phyical PAN Card",
            Range: 510,
            rate: 10,
            transaction: 1,
            income: 15
          }, 
          {
            benefits: "General Insurance Premium Amont",
            Range: 10000,
            rate: 10,
            transaction: 1,
            income: 1000
          },
    ];
 

    const [incomeValues, setIncomeValues] = useState(bankingData.map(() => '1'));
    const [newData, setNewData] = useState([]);
    const [incomePerDay, setIncomePerDay] = useState(0); // New state for total income

    let totalIncome = incomePerDay * 30;

    const handleIncomeChange = (index, value) => {
        const newIncomeValues = [...incomeValues];
        newIncomeValues[index] = value;
        setIncomeValues(newIncomeValues);
    };

    useEffect(() => {
        // Calculate new values based on the formula
        const newBankingData = bankingData.map((ele, index) => {
            const inputValue = parseFloat(incomeValues[index]) || 0;

            const newIncome = ele.Range === "Flat"
                ? inputValue
                : (ele.Range * ele.transaction * ele.rate * inputValue) / 100;
            return { ...ele, income: newIncome };
        });

        // Update the banking data with the new values
        setNewData(newBankingData);

        // Calculate and update the total income
        const newIncomePerDay = newBankingData.reduce((total, ele) => total + ele.income, 0);
        setIncomePerDay(newIncomePerDay);

    }, [incomeValues]);


    function scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }
  


    return (
      

<Box margin={'2rem'}>
  <Box margin={['20px', '40px']}>
    <Heading color={'#c047cc'} fontSize={['4xl', '5xl']} fontWeight="bold">
      Income Calculator
    </Heading>
    <br />
    <Heading color={'#c452de'} fontSize={['xl', '2xl']} fontWeight="bold">
      Add the number of transactions to calculate earnings.
    </Heading>
  </Box>

  <Flex direction={['column', 'row']}>

    {/* Table */}
    <Box boxShadow={['none', '2xl']} borderRadius={'20px'} marginBottom={['2rem', '0']} marginRight={['0', '20px']}>
      <TableContainer borderRadius={'20px'}>
        <Table variant='striped' colorScheme='gray.100'>
          <Thead bg={'#c554e1'} color='white'>
            <Tr>
              <Th color='white' fontSize={['md', '1xl']}>
                Our Services
              </Th>
              <Th color='white' fontSize={['md', '1xl']}>
                Transaction Per Day
              </Th>
              <Th color='white' fontSize={['md', '1xl']} isNumeric>
                Income(₹)
              </Th>
            </Tr>
          </Thead>
          {newData.map((ele, index) => {
            return (
              <Tbody key={index} height={['80px', '100px']}>
                <Tr key={index}>
                  <Td fontSize={['md', '1xl']}>
                    <Text color={'black'} fontSize={['xl', '2xl']}>
                      {ele.benefits}
                    </Text>
                    <Text>Avg transaction of ₹{ele.Range}</Text>
                  </Td>
                  <Td w={['40px', '50px']}>
                    <Input
                      textAlign={'center'}
                      value={incomeValues[index]}
                      onChange={(e) => handleIncomeChange(index, e.target.value)}
                      borderRadius={['8px', '10px']}
                      borderColor={'black solid'}
                      _hover={'black'}
                    />
                  </Td>
                  <Td isNumeric paddingRight={['40px', '60px']}>{ele.income}</Td>
                </Tr>
              </Tbody>
            );
          })}
        </Table>
      </TableContainer>
    </Box>

    {/* Image and Total Income */}
    <Box boxShadow={['none']} borderRadius={'20px'}>
      <Card maxW={['100%', '100%']} borderRadius={'20px'} backgroundColor={'#c554e1'}>
        <Box borderRadius={'20px'} backgroundColor={'#bc3cbb'}>
          <CardHeader>
            <Center>
              <Flex direction={['column', 'row']} spacing={['2', '4']} align='center'>
                <Box marginBottom={['2', '0']}>
                  <Heading size={['md', 'lg']} color='white'>
                    Income Per Day
                  </Heading>
                  <Text size={['md', 'xl']} color='white' fontSize={['3xl', '4xl']}>
                    {incomePerDay}
                  </Text>
                </Box>
                <Box size={['md', 'md']} marginLeft={['0', '4']} marginRight={['0', '4']} marginBottom={['4', '4']}
                >
                  <Heading size={['lg', 'lg']} height={['16px', '20px']} color='white'>
                    x
                  </Heading>
                </Box>
                <Box>
                  <Heading size={['md', 'lg']} color='white'>
                    Number of Days
                  </Heading>
                  <Text fontSize={['xl', '4xl']} color='white'>
                    {30}
                  </Text>
                </Box>
              </Flex>
            </Center>
          </CardHeader>
          <CardBody>
            <Image borderRadius={['20px', '20px']} alt={'income image'} src={income} />
          </CardBody>
          <CardFooter borderRadius={['20px', '20px']}>
            <Box>
              <Heading size={['md', 'lg']} color='white'>
                Total Income
              </Heading>
              <Flex direction='column' align='center'>
                <Text size={['md', '6xl']} color='white' fontSize={['6xl', '6xl']}>
                  {totalIncome}
                </Text>
                <Text size={['sm', '2xl']} color='white' fontSize={['2xl', '2xl']} paddingTop={['10px', '20px']}>
                  /Month
                </Text>
              </Flex>
            </Box>
          </CardFooter>
          <CardFooter>
            <Center>
            <Link to="/login">
              <Button  height={['48px', '58px']} width={['20rem', '25rem','34rem']} backgroundColor='white' _hover={'blue.100'}  onClick={scrollToTop}>
                Join HPNPAY
              </Button>
              </Link>
            </Center>
          </CardFooter>
        </Box>
      </Card>
    </Box>
  </Flex>
</Box>


       
  );
}
   
 export default IncomeCalculator;


